import request from '@/utils/request'

/**
 * 获取现金申请单列表
 */
export const getApplyForCashRecordList = (params) => {
  return request({
    url: '/shop/apply/getApplyForCashRecordList',
    method: 'GET',
    params,
  })
}

/**
 * 获取现金申请单详情
 */
export const getApplyForCashDetails = (id) => {
  return request({
    url: `/shop/apply/getApplyForCashDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 获取收银员信息label
 */
export const getCashierInfoLabel = () => {
  return request({
    url: '/shop/apply/getCashierInfoLabel',
    method: 'GET',
  })
}

/**
 * 批量审核现金申请单
 */
export const reviewApplyForCash = (data) => {
  return request({
    url: '/shop/apply/reviewApplyForCash',
    method: 'POST',
    data,
  })
}

/**
 * 单个审核现金申请单
 */
export const reviewSingleApplyForCash = (data) => {
  return request({
    url: '/shop/apply/reviewSingleApplyForCash',
    method: 'POST',
    data,
  })
}

/**
 * 获取现金领取单事件记录
 */
export const getEventRecord = (id) => {
  return request({
    url: `/shop/apply/getEventRecord/${id}`,
    method: 'GET',
  })
}
