<template>
  <div>
    <i
      class="icon-massho-b-shouyintai"
      style="font-size: 24px"
      @click="cashier"
    ></i>
    <!-- 收银台选择 -->
    <CashierSelect ref="cashierSelectRef" v-model="selectCounterDialogFlag" />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { loadStoreNoLoading } from '@/utils/store'
import CashierSelect from '@/components/cashier/CashierSelect.vue'

const selectCounterDialogFlag = ref(false)
const cashierSelectRef = ref(null)

const cashier = () => {
  loadStoreNoLoading('shopOrder/getAccountCounter').then((data) => {
    if (data.status === 1) {
      selectCounterDialogFlag.value = false
      setTimeout(() => {
        window.open('/shop/checkout')
      }, 100)
    } else {
      cashierSelectRef.value.getCashierCounterList()
    }
  })
}
</script>

<style lang="scss" scoped></style>
