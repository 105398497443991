import { getItem, setItem } from '@/utils/localStorage'
import { LANG, TAGS_VIEW } from '@/constant/index'
import router from '@/router'

export default {
  namespaced: true,
  state: () => ({
    sidebarOpened: true,
    language: getItem(LANG) || 'zh',
    tagsViewList: getItem(TAGS_VIEW) || [],
    // 0: 其他页面，1：在组织页面。2：在店铺页面
    currLocation: 0,
    // 所在结构页面是否发生变化，组织 => 店铺、店铺 => 组织、其他 => 组织、店铺 => 其他 等等
    currLocationChange: false,
    shopViewKeepAliveList:
      (getItem(TAGS_VIEW) && getItem(TAGS_VIEW).map((item) => item.name)) || [],
    timeZone: undefined,
    ticketMachineParams: {
      // 控制显示和隐藏
      flag: false,
      // 来源，1：店铺。2：收银台
      source: 1,
      // 回调方法
      callback: () => {},
    },
  }),
  mutations: {
    triggerSidebarOpened(state, flag) {
      if (flag !== undefined) {
        state.sidebarOpened = flag
        return
      }

      state.sidebarOpened = !state.sidebarOpened
    },
    setLanguage(state, lang) {
      setItem(LANG, lang)
      state.language = lang
    },
    addTagsViewList(state, tag) {
      // 重复的就不添加了
      const isFind = state.tagsViewList.find((item) => {
        return item.path === tag.path
      })
      if (!isFind) {
        // 超过10个就移除第一个
        if (state.tagsViewList.length >= 8) {
          state.tagsViewList.shift()
          state.shopViewKeepAliveList.shift()
        }
        state.tagsViewList.push(tag)
        // keep-alive 缓存配置
        state.shopViewKeepAliveList.push(tag.name)
        setItem(TAGS_VIEW, state.tagsViewList)
      }
    },
    changeTagsView(state, { index, tag }) {
      state.tagsViewList[index] = tag
      setItem(TAGS_VIEW, state.tagsViewList)
    },
    /**
     * 移除 Tags
     * @param {type: 'index' || 'other' || 'left' || 'right', index: 1} payload
     */
    removeTagsView(state, payload) {
      if (payload.type === 'index') {
        state.tagsViewList.splice(payload.index, 1)
        state.shopViewKeepAliveList.splice(payload.index, 1)
      } else if (payload.type === 'other') {
        state.tagsViewList.splice(
          payload.index + 1,
          state.tagsViewList.length - (payload.index + 1),
        )
        state.shopViewKeepAliveList.splice(
          payload.index + 1,
          state.shopViewKeepAliveList.length - (payload.index + 1),
        )
        state.tagsViewList.splice(0, payload.index)
        state.shopViewKeepAliveList.splice(0, payload.index)
      } else if (payload.type === 'left') {
        state.tagsViewList.splice(0, payload.index)
        state.shopViewKeepAliveList.splice(0, payload.index)
      } else if (payload.type === 'right') {
        state.tagsViewList.splice(
          payload.index + 1,
          state.tagsViewList.length - (payload.index + 1),
        )
        state.shopViewKeepAliveList.splice(
          payload.index + 1,
          state.shopViewKeepAliveList.length - (payload.index + 1),
        )
      }
      setItem(TAGS_VIEW, state.tagsViewList)
    },
    /**
     * 移除所有 Tags
     */
    removeAllTagsView(state) {
      state.tagsViewList.splice(0, state.tagsViewList.length)
      state.shopViewKeepAliveList.splice(0, state.shopViewKeepAliveList.length)
      setItem(TAGS_VIEW, state.tagsViewList)
    },
    // 关闭当前路由 Tag
    closeCurrentTag(state) {
      // 当前路由组件名称
      const routeName = router.currentRoute.value.name
      const index = state.shopViewKeepAliveList.indexOf(routeName)
      if (index !== -1) {
        setTimeout(() => {
          state.tagsViewList.splice(index, 1)
          state.shopViewKeepAliveList.splice(index, 1)
          setItem(TAGS_VIEW, state.tagsViewList)
        }, 100)
      }
    },
    // 关闭指定路由名 Tag
    closeNameTag(state, name) {
      const index = state.shopViewKeepAliveList.indexOf(name)
      if (index !== -1) {
        state.tagsViewList.splice(index, 1)
        state.shopViewKeepAliveList.splice(index, 1)
        setItem(TAGS_VIEW, state.tagsViewList)
      }
    },
    // 设置当前所在页面位置
    setCurrlocation(state, currLocation) {
      state.currLocationChange = state.currLocation !== currLocation
      state.currLocation = currLocation
    },
    // 设置当前所在页面位置
    setTimeZone(state, timeZone) {
      state.timeZone = timeZone
    },
    // 设置小票机相关参数
    triggerTicketMachineParams(state, params) {
      if (!params.flag) {
        params.flag = false
      }
      if (!params.source) {
        params.source = 1
      }
      state.ticketMachineParams = params
    },
    // 设置小票机显示和隐藏
    triggerTicketMachineFlag(state, flag) {
      state.ticketMachineParams.flag = flag
    },
  },
  actions: {
    closeCurrentTag(state) {
      this.commit('app/closeCurrentTag')
    },
  },
}
