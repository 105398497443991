import {
  getOnlineOrderList,
  getOnlineOrderDetail,
  manualStocking,
  confirmReceipt,
  getMacouUseList,
  getMacouRebateList,
  getRefundMacouUseList,
  getRefundMacouRebateList,
  getMacouUseListIpad,
  getMacouRebateListIPad,
  getRefundMacouUseListIPad,
  getRefundMacouRebateListIPad,
  getSkuInfoByOrderId,
  getSkuInfoByOrderIdIPad,
  requestRefund,
  guaranteeServiceList,
  guaranteeServiceListByIpad,
  getStatusTracking,
  checkOrderTimeExpire,
  getRefundOrder,
  getAvailableAndPrintCashierCounterList,
  printOnlineTicket,
  printOnlineRefundTicket,
  getRefundDesById,
  getRefundDesByIdIPad,
  getOnlineOrderPickingTime,
  getOnlineAmountCollected,
  getOnlineActualAmountReceived,
  getAccountCounter,
} from '@/api/shop/onlineOrder'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getOnlineOrderList(context, params) {
      return await getOnlineOrderList(params)
    },
    async getOnlineOrderDetail(context, id) {
      return await getOnlineOrderDetail(id)
    },
    async manualStocking(context, orderId) {
      return await manualStocking(orderId)
    },
    async confirmReceipt(context, data) {
      return await confirmReceipt(data)
    },
    async getMacouUseList(context, orderId) {
      return await getMacouUseList(orderId)
    },
    async getMacouRebateList(context, orderId) {
      return await getMacouRebateList(orderId)
    },
    async getRefundMacouUseList(context, id) {
      return await getRefundMacouUseList(id)
    },
    async getRefundMacouRebateList(context, id) {
      return await getRefundMacouRebateList(id)
    },
    async getMacouUseListIpad(context, orderId) {
      return await getMacouUseListIpad(orderId)
    },
    async getMacouRebateListIPad(context, orderId) {
      return await getMacouRebateListIPad(orderId)
    },
    async getRefundMacouUseListIPad(context, id) {
      return await getRefundMacouUseListIPad(id)
    },
    async getRefundMacouRebateListIPad(context, id) {
      return await getRefundMacouRebateListIPad(id)
    },
    async getSkuInfoByOrderId(context, id) {
      return await getSkuInfoByOrderId(id)
    },
    async getSkuInfoByOrderIdIPad(context, id) {
      return await getSkuInfoByOrderIdIPad(id)
    },
    async requestRefund(context, data) {
      return await requestRefund(data)
    },
    async guaranteeServiceList(context, orderId) {
      return await guaranteeServiceList(orderId)
    },
    async guaranteeServiceListByIpad(context, orderId) {
      return await guaranteeServiceListByIpad(orderId)
    },
    async getStatusTracking(context, orderId) {
      return await getStatusTracking(orderId)
    },
    async checkOrderTimeExpire(context, orderId) {
      return await checkOrderTimeExpire(orderId)
    },
    async getRefundOrder(context, id) {
      return await getRefundOrder(id)
    },
    async getAvailableAndPrintCashierCounterList(context) {
      return await getAvailableAndPrintCashierCounterList()
    },
    async printOnlineTicket(context, data) {
      return await printOnlineTicket(data)
    },
    async printOnlineRefundTicket(context, data) {
      return await printOnlineRefundTicket(data)
    },
    async getRefundDesById(context, id) {
      return await getRefundDesById(id)
    },
    async getRefundDesByIdIPad(context, id) {
      return await getRefundDesByIdIPad(id)
    },
    async getOnlineOrderPickingTime(context, id) {
      return await getOnlineOrderPickingTime(id)
    },
    async getOnlineAmountCollected(context, id) {
      return await getOnlineAmountCollected(id)
    },
    async getOnlineActualAmountReceived(context, id) {
      return await getOnlineActualAmountReceived(id)
    },
    async getAccountCounter(context) {
      return await getAccountCounter()
    },
  },
}
