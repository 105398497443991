import request from '@/utils/request'

/**
 * 获取现金缴款单列表
 */
export const getContributionsRecordList = (params) => {
  return request({
    url: '/shop/contributions/getContributionsRecordList',
    method: 'GET',
    params,
  })
}

/**
 * 获取现金缴款单详情
 */
export const getContributionsDetails = (id) => {
  return request({
    url: `/shop/contributions/getContributionsDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 获取现金缴款单事件记录
 */
export const getEventRecord = (id) => {
  return request({
    url: `/shop/contributions/getEventRecord/${id}`,
    method: 'GET',
  })
}

/**
 * 获取收银员信息label
 */
export const getCashierInfoLabel = () => {
  return request({
    url: '/shop/contributions/getCashierInfoLabel',
    method: 'GET',
  })
}

/**
 * 批量审核现金缴款单
 */
export const reviewContributions = (data) => {
  return request({
    url: '/shop/contributions/reviewContributions',
    method: 'POST',
    data,
  })
}

/**
 * 单个审核现金缴款单
 */
export const reviewSingleContributions = (data) => {
  return request({
    url: '/shop/contributions/reviewSingleContributions',
    method: 'POST',
    data,
  })
}
