<template>
  <div>
    <el-popover
      placement="top-start"
      trigger="hover"
      :content="content"
      :show-after="showAfter"
      :disabled="!enabledPopover"
      :popper-class="popperClass"
    >
      <template #reference>
        <el-text class="hover:cursor-pointer" @click="toCopy(content)">{{
          content
        }}</el-text>
      </template>
    </el-popover>
  </div>
</template>

<script setup>
import { copy } from '@/utils/copy'

const props = defineProps({
  // 最多显示行数。大漠知秋 - TODO: 先注释，后续修复
  /* line: {
    type: Number,
    default: 1,
  }, */
  // 显示的内容
  content: {
    type: String,
    default: '',
  },
  // 是否开启点击复制功能
  copy: {
    type: Boolean,
    default: true,
  },
  // 开启 popover 功能
  enabledPopover: {
    type: Boolean,
    default: true,
  },
  // popover 延迟多久显示
  showAfter: {
    type: Number,
    default: 500,
  },
  popperClass: {
    type: String,
    default: '',
  },
})

const toCopy = () => {
  if (!props.copy) return
  copy(props.content)
}
</script>
