import request from '@/utils/request'

/**
 * 获取当班收银员信息
 */
export const getCashierInfo = () => {
  return request({
    url: '/shop/shifts/getCashierInfo',
    method: 'GET',
  })
}

/**
 * 收款总览
 */
export const getCollectionOverview = (id) => {
  return request({
    url: `/shop/shifts/getCollectionOverview/${id}`,
    method: 'GET',
  })
}

/**
 * 缴款明细
 */
export const getPaymentDetails = (id) => {
  return request({
    url: `/shop/shifts/getPaymentDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 获取交班收银员lable
 */
export const getCashierLabel = () => {
  return request({
    url: '/shop/shifts/getCashierLabel',
    method: 'GET',
  })
}

/**
 * 领取现金
 */
export const collectCash = (data) => {
  return request({
    url: '/shop/shifts/collectCash',
    method: 'POST',
    data,
  })
}

/**
 * 上缴现金
 */
export const submitCash = (data) => {
  return request({
    url: '/shop/shifts/submitCash',
    method: 'POST',
    data,
  })
}

/**
 * 检查当前账号是否存在待审核的金额
 */
export const getCheckAmountExists = () => {
  return request({
    url: '/shop/shifts/getCheckAmountExists',
    method: 'GET',
  })
}

/**
 * 检查当前账号是否存在未上缴的现金
 */
export const getSubmitAmountExists = () => {
  return request({
    url: '/shop/shifts/getSubmitAmountExists',
    method: 'GET',
  })
}

/**
 * 结束班次
 */
export const endShift = () => {
  return request({
    url: '/shop/shifts/endShift',
    method: 'POST',
  })
}

/**
 * 交班
 */
export const shiftHandover = (data) => {
  return request({
    url: '/shop/shifts/shiftHandover',
    method: 'POST',
    data,
  })
}

/**
 * 获取登录账号的收银台信息
 */
export const getAccountCounter = () => {
  return request({
    url: '/shop/shifts/getAccountCounter',
    method: 'GET',
  })
}

/**
 * 获取交班记录（iPad）
 */
export const getHandoverRecordList = (params) => {
  return request({
    url: '/shop/shifts/getHandoverRecordList',
    method: 'GET',
    params,
  })
}

/**
 * 获取交班记录收银员信息IPad
 */
export const getCashierRecordInfo = (id) => {
  return request({
    url: `/shop/shifts/getCashierRecordInfo/${id}`,
    method: 'GET',
  })
}

/**
 * 打印交班小票
 */
export const printShiftTicket = (data) => {
  return request({
    url: '/shop/shifts/printShiftTicket',
    method: 'POST',
    data,
  })
}

/**
 * 值班人员交班领取现金记录列表
 */
export const collectCashRecord = () => {
  return request({
    url: '/shop/shifts/collectCashRecord',
    method: 'GET',
  })
}

/**
 * 值班人员交班上缴现金记录列表
 */
export const cashSubmissionRecord = () => {
  return request({
    url: '/shop/shifts/cashSubmissionRecord',
    method: 'GET',
  })
}
