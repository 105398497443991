import {
  getApplyForCashRecordList,
  getApplyForCashDetails,
  getCashierInfoLabel,
  reviewApplyForCash,
  reviewSingleApplyForCash,
  getEventRecord,
} from '@/api/shop/financialApply'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getApplyForCashRecordList(context, params) {
      return await getApplyForCashRecordList(params)
    },
    async getApplyForCashDetails(context, id) {
      return await getApplyForCashDetails(id)
    },
    async getCashierInfoLabel(context) {
      return await getCashierInfoLabel()
    },
    async reviewApplyForCash(context, data) {
      return await reviewApplyForCash(data)
    },
    async reviewSingleApplyForCash(context, data) {
      return await reviewSingleApplyForCash(data)
    },
    async getEventRecord(context, id) {
      return await getEventRecord(id)
    },
  },
}
