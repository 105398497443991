import {
  getCashierInfo,
  getCollectionOverview,
  getPaymentDetails,
  getCashierLabel,
  collectCash,
  submitCash,
  getCheckAmountExists,
  getSubmitAmountExists,
  endShift,
  shiftHandover,
  getAccountCounter,
  getHandoverRecordList,
  getCashierRecordInfo,
  printShiftTicket,
  collectCashRecord,
  cashSubmissionRecord,
} from '@/api/shop/handover'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getCashierInfo(context) {
      return await getCashierInfo()
    },
    async getCollectionOverview(context, id) {
      return await getCollectionOverview(id)
    },
    async getPaymentDetails(context, id) {
      return await getPaymentDetails(id)
    },
    async getCashierLabel(context) {
      return await getCashierLabel()
    },
    async collectCash(context, data) {
      return await collectCash(data)
    },
    async submitCash(context, data) {
      return await submitCash(data)
    },
    async getCheckAmountExists(context) {
      return await getCheckAmountExists()
    },
    async getSubmitAmountExists(context) {
      return await getSubmitAmountExists()
    },
    async endShift(context) {
      return await endShift()
    },
    async shiftHandover(context, data) {
      return await shiftHandover(data)
    },
    async getAccountCounter(context) {
      return await getAccountCounter()
    },
    async getHandoverRecordList(context, params) {
      return await getHandoverRecordList(params)
    },
    async getCashierRecordInfo(context, id) {
      return await getCashierRecordInfo(id)
    },
    async printShiftTicket(context, data) {
      return await printShiftTicket(data)
    },
    async collectCashRecord(context) {
      return await collectCashRecord()
    },
    async cashSubmissionRecord(context) {
      return await cashSubmissionRecord()
    },
  },
}
