<template>
  <div>
    <!-- 切换打票机 -->
    <el-dialog
      :title="$t('msg.orderManage.pleaseSelectTicketMachine')"
      v-model="selectTicketMachineDialog"
      :width="source === 1 ? '25%' : '45%'"
      :class="[source === 1 ? 'ticket-common-normal' : 'ticket-common-ipad']"
      center
    >
      <div class="ticket-container">
        <el-radio-group v-model="currentTicketMachineId" class="ml-4">
          <div>
            <el-radio
              class="block"
              v-for="item in availableTicketMachineList"
              :key="item"
              :value="item.id"
              >{{ item.name }}</el-radio
            >
          </div>
        </el-radio-group>
        <div v-if="availableTicketMachineList.length < 1">
          <span class="no-ticket-span">{{
            $t('msg.orderManage.thereIsNoTicketYet')
          }}</span>
          <el-button
            class="no-ticket-btn"
            style="margin-left: 10px"
            type="primary"
            ><a
              href="/shop/shopManage/printersManagement"
              style="text-decoration: none"
            >
              {{ $t('msg.orderManage.goToAdd') }}
            </a></el-button
          >
        </div>
      </div>

      <template #footer>
        <el-button @click="selectTicketMachineDialog = false">
          {{ $t('msg.common.cancel') }}
        </el-button>
        <el-button
          type="primary"
          :disabled="!currentTicketMachineId"
          @click="printReceipt"
        >
          {{ $t('msg.orderManage.startPrinting') }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed } from 'vue'
import { loadStore } from '@/utils/store'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  source: {
    type: Number,
    default: 1,
  },
})

const emit = defineEmits(['update:modelValue', 'print-receipt'])
const selectTicketMachineDialog = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  },
})

onMounted(() => {
  initLoad()
})

// 小票机
const currentTicketMachineId = ref(null)
const availableTicketMachineList = reactive([])
const printReceipt = () => {
  if (!currentTicketMachineId.value) {
    return
  }
  emit('print-receipt', currentTicketMachineId.value)
}

const initLoad = () => {
  loadStore('shop/getOnlineTicket').then((data) => {
    availableTicketMachineList.splice(0, availableTicketMachineList.length)
    availableTicketMachineList.push(...data)
    selectTicketMachineDialog.value = true
    loadStore('shop/getOnDutyTicket').then((data) => {
      if (data.id && data.status === 1) {
        currentTicketMachineId.value = data.id
      }
    })
  })
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.ticket-common-ipad {
  .el-dialog__title {
    font-size: 30px;
  }
  .ticket-container {
    margin-bottom: 2px;
    margin-left: 50px;

    .el-radio {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
    .el-radio__inner {
      transform: scale(1.9);
    }
    .el-radio__label {
      font-size: 25px;
      margin-left: 8px;
    }

    .no-ticket-span {
      font-size: 25px;
    }

    .no-ticket-btn {
      font-size: 30px;
      padding: 1.5rem;
    }
  }
  .el-dialog__footer {
    .el-button {
      font-size: 25px;
      padding: 25px;
      min-width: 12rem;
    }
  }
}
</style>
