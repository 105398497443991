import {
  getContributionsRecordList,
  getContributionsDetails,
  getEventRecord,
  getCashierInfoLabel,
  reviewContributions,
  reviewSingleContributions,
} from '@/api/shop/financialReceipt'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getContributionsRecordList(context, params) {
      return await getContributionsRecordList(params)
    },
    async getContributionsDetails(context, id) {
      return await getContributionsDetails(id)
    },
    async getEventRecord(context, id) {
      return await getEventRecord(id)
    },
    async getCashierInfoLabel(context) {
      return await getCashierInfoLabel()
    },
    async reviewContributions(context, data) {
      return await reviewContributions(data)
    },
    async reviewSingleContributions(context, data) {
      return await reviewSingleContributions(data)
    },
  },
}
