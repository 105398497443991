// 用户长时间未操作 退出登录
import { loadStore } from '@/utils/store'
import { getItem, setItem } from '@/utils/localStorage'
import { TOKEN, SESSION_TIME } from '@/constant'
class SessionTimer {
  // # timeOut，允许最长未操作时间。默认：600 * 1000 = 10 分钟。
  #timeOut = null
  #timer = null

  // 更新回话时长
  handleResetSessionTime = (minutes) => {
    // this.#i = 1
    this.#timeOut = minutes * 60 * 1000
  }

  // 当有任何操作时，更新最新操作时间
  #handleResetLatestTime = () => {
    // this.#i = 1
    setItem(SESSION_TIME, new Date().getTime())
  }

  startMonitoring() {
    if (!getItem(TOKEN)) {
      return
    }
    if (!getItem(SESSION_TIME)) {
      this.#handleResetLatestTime()
    }
    if (this.#timer) {
      return
    }
    loadStore('orgAccount/getSessionTime').then((data) => {
      this.#timeOut = data ? data * 60 * 1000 : 600 * 1000
      this.#handleInterval()
    })
    // 单击事件
    document.onclick = this.#handleResetLatestTime
    // 双击事件
    document.ondblclick = this.#handleResetLatestTime
    // 键盘按下
    document.onkeydown = this.#handleResetLatestTime
  }

  #handleInterval = () => {
    this.#timer = setInterval(() => {
      const currentTime = new Date().getTime()
      const lastTime = getItem(SESSION_TIME)
      if (!lastTime) {
        clearInterval(this.#timer)
      } else if (currentTime - lastTime > this.#timeOut) {
        clearInterval(this.#timer)
        loadStore('user/sessionTimeoutLogout')
      }
    }, 1000)
  }
}

export default new SessionTimer()
