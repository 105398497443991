import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getItem } from '@/utils/localStorage'
import { LANG, SHOP_ID, SHOP_ID_HEADER_KEY, TOKEN } from '@/constant'
import { loadStore } from './store'
import cloneDeep from 'lodash/cloneDeep'
import { jsonBig } from './json'
import i18n from '@/i18n'
import { shopDefaultPrefix } from '@/router'
import { sessionTimeoutBox } from '@/components/session-timeout-box/SessionTimeoutBox'

// import store from '@/store'

// #region 基础请求
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // timeout: 5000
  transformResponse: [
    (data) => {
      try {
        const finalData = jsonBig.parse(data)
        return finalData
      } catch (err) {
        console.error('transformResponse Err:', err)
        console.error('data:', data)
        // 如果转换失败，则包装为统一数据格式并返回
        return data
      }
    },
  ],
})

const locale = {
  'zh-CN': 'zh_CN',
  en: 'en_US',
}

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    if (getItem(TOKEN)) {
      // token
      config.headers.Token = getItem(TOKEN)
    }
    if (getItem(LANG)) {
      // 国际化
      config.headers.Locale = locale[getItem(LANG)]
    }
    if (config.url.startsWith(shopDefaultPrefix)) {
      // 店铺的内容，就加上店铺的 ID
      const shopId = getItem(SHOP_ID)
      if (shopId) {
        config.headers[SHOP_ID_HEADER_KEY] = getItem(SHOP_ID)
      } else {
        window.location.href = '/'
        return Promise.reject(new Error('Request blocked'))
      }
    }
    // 移除空字符串，null, undefined
    clearEmptyParam(config)
    return config
  },
  (err) => {
    return Promise.reject(err)
  },
)

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    const { code, success, message, data } = response.data
    if (success && code === 200) {
      return data
    } else if (success && code === 201) {
      ElMessage.success(message)
      return data
    } else if (success && code === 202) {
      // ElMessage.warning(message)
      ElMessageBox.alert(message, i18n.global.t('msg.common.tip'), {
        confirmButtonText: i18n.global.t('msg.common.ok'),
        showCancelButton: false,
        closeOnClickModal: true,
        closeOnPressEscape: true,
        callback: (action) => {},
      })
      return Promise.reject(new Error(message))
    } else if (code === 302) {
      window.location.href = data
      return ''
    } else {
      // ElMessage.error(message)
      if (code === 401) {
        loadStore('user/logout', true).then(() => {
          sessionTimeoutBox.dialog()
        })
        return Promise.reject(
          new Error(
            i18n.global.t('msg.common.loginHasExpiredPleaseLogInAgain'),
          ),
        )
      } else {
        ElMessageBox.alert(message, i18n.global.t('msg.common.tip'), {
          confirmButtonText: i18n.global.t('msg.common.ok'),
          showCancelButton: false,
          closeOnClickModal: true,
          closeOnPressEscape: true,
          callback: (action) => {},
        })
      }
      return Promise.reject(new Error(message))
    }
  },
  (err) => {
    if (err.response && err.response.data && err.response.data.code === 401) {
      // 登录过期
      loadStore('user/logout').then(() => {
        sessionTimeoutBox.dialog()
      })
      ElMessage.error(
        i18n.global.t('msg.common.loginHasExpiredPleaseLogInAgain'),
      )
      return Promise.reject(err)
    }

    ElMessage.error('Request exception: ' + err.response.status)
    return Promise.reject(err)
  },
)
// #endregion

// #region 下载请求
export const requestDownload = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // timeout: 5000
})
// 请求拦截器
requestDownload.interceptors.request.use(
  (config) => {
    if (getItem(TOKEN)) {
      // token
      config.headers.Token = getItem(TOKEN)
    }
    if (getItem(LANG)) {
      // 国际化
      config.headers.Locale = locale[getItem(LANG)]
    }
    if (config.url.startsWith(shopDefaultPrefix)) {
      // 店铺的内容，就加上店铺的 ID
      config.headers[SHOP_ID_HEADER_KEY] = getItem(SHOP_ID)
    }
    // 移除空字符串，null, undefined
    clearEmptyParam(config)
    return config
  },
  (err) => {
    return Promise.reject(err)
  },
)

export const downloadToLocal = (data, name) => {
  const link = document.createElement('a') // 创建元素
  const blob = new Blob([data])
  link.style.display = 'none'
  link.href = URL.createObjectURL(blob) // 创建下载的链接
  link.setAttribute('download', name) // 给下载后的文件命名 fileName文件名  type文件格式
  document.body.appendChild(link)
  link.click() // 点击下载
  document.body.removeChild(link) //  下载完成移除元素
  window.URL.revokeObjectURL(link.href) // 释放掉blob对象
}
// #endregion

// 移除空字符串，null, undefined
function clearEmptyParam(config) {
  ;['data', 'params'].forEach((item) => {
    if (config[item]) {
      const keys = Object.keys(config[item])
      if (keys.length) {
        keys.forEach((key) => {
          const rawType = toRawType(config[item])
          if (
            ['', undefined, null].includes(config[item][key]) &&
            ['Object'].includes(rawType)
          ) {
            // 移除属性之前，进行深拷贝断开引用，避免影响页面
            config[item] = cloneDeep(config[item])
            delete config[item][key]
          }
        })
      }
    }
  })
}

/**
 * @description 获取原始类型
 * @param {*} value
 * @returns {String} 类型字符串，如'String', 'Object', 'Null', 'Boolean', 'Number', 'Array'
 */
function toRawType(value) {
  return Object.prototype.toString.call(value).slice(8, -1)
}

export default request
