<template>
  <div>
    <!-- 选择收银台弹框 -->
    <el-dialog
      v-model="selectCounterDialogFlag"
      :title="$t('msg.orderManage.pleaseSelectTheCheckoutCounter')"
      width="40%"
      append-to-body
    >
      <div>
        <el-table
          ref="selectCounterRef"
          :data="selectCounterList"
          @current-change="handleSelectCounterCurrentChange"
        >
          <el-table-column width="30">
            <template #default="{ row }">
              <el-radio
                :value="row.id"
                v-model="selectCounterId"
                :disabled="row.status === 1"
              ></el-radio>
            </template>
          </el-table-column>
          <el-table-column
            prop="cashierName"
            :label="$t('msg.orderManage.cashierName')"
          ></el-table-column>
          <el-table-column prop="status" :label="$t('msg.common.status')">
            <template #default="{ row }">
              <span v-if="row.status === 1" class="text-[red]">{{
                $t('msg.orderManage.alreadyOccupied')
              }}</span>
              <span v-else>--</span>
            </template></el-table-column
          >
          <el-table-column prop="staff" :label="$t('msg.orderManage.employee')">
            <template #default="{ row }">
              <span v-if="row.staff">{{ row.staff }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('msg.common.operation')">
            <template #default="{ row }">
              <el-link
                v-if="row.status === 1"
                type="primary"
                @click="forcedOffline(row.id)"
                >{{ $t('msg.orderManage.forcedOffline') }}</el-link
              >
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mt-15">
        <span class="text-[#F59A23]">{{
          $t('msg.orderManage.alreadyOccupiedTips')
        }}</span>
      </div>
      <template #footer>
        <div>
          <el-button @click="selectCounterDialogFlag = false">
            {{ $t('msg.common.cancel') }}
          </el-button>
          <el-button
            type="primary"
            @click="confirmSelected"
            :disabled="!selectCounterId"
            >{{ $t('msg.common.confirm') }}</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!-- 权限不足弹框 -->
    <el-dialog
      append-to-body
      v-model="insufficientPermissionsDialog"
      :title="$t('msg.common.tip')"
      width="25%"
    >
      <div>
        <div class="text-[20px] mt-20">
          {{ $t('msg.common.sorryInsufficientPermissions1') }}
        </div>
        <div class="flex j-end">
          <el-link
            class="text-[17px] mt-20"
            type="primary"
            @click="selectAdministrator"
          >
            {{ $t('msg.common.pleaseSelectAdministrator') }}>
          </el-link>
        </div>
      </div>
      <template #footer>
        <div class="flex j-center">
          <el-button
            type="primary"
            @click="insufficientPermissionsDialog = false"
          >
            {{ $t('msg.common.iKnow') }}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 管理员验证弹框 -->
    <el-dialog
      append-to-body
      v-model="administratorDialog"
      :title="$t('msg.orderManage.administratorVerification')"
      width="25%"
    >
      <el-form
        ref="administratorFormRef"
        :model="administratorForm"
        :rules="administratorFormRules"
        label-width="auto"
      >
        <el-form-item prop="id" :label="$t('msg.common.administrators')">
          <el-select
            v-model="selectAdmin"
            :placeholder="$t('msg.common.pleaseSelect')"
            @change="administratorChange"
          >
            <el-option
              v-for="item in administratorOptions"
              :key="item"
              :value="item"
              :label="item.label"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('msg.login.email')"
          v-if="administratorForm.id"
        >
          <el-input v-model="administratorForm.email" disabled />
        </el-form-item>
        <el-form-item
          prop="password"
          :label="$t('msg.common.password')"
          v-if="administratorForm.id"
        >
          <el-input v-model="administratorForm.password" type="password">
            <template #prefix>
              <el-icon><Lock /></el-icon>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="flex j-center">
          <el-button @click="administratorDialog = false">
            {{ $t('msg.common.cancel') }}
          </el-button>
          <el-button
            type="primary"
            :disabled="!administratorForm.id"
            @click="adminVerification"
          >
            {{ $t('msg.common.verification') }}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 换班收银员确认弹框 -->
    <el-dialog
      append-to-body
      v-model="shiftChangeCashierConfirmationDialog"
      :title="$t('msg.orderManage.shiftChangeCashierConfirmation')"
      width="25%"
    >
      <div class="flex j-center items-center">
        <span>{{ $t('msg.orderManage.cashier') + ': ' }}</span>
        <span>{{ shiftChangeCashier.counterName }}</span>
      </div>
      <div class="flex j-center items-center my-20 text-[20px] text-[red]">
        <span>{{ $t('msg.orderManage.handoverAmount') + ': ' }}</span>
        <span>{{ '$' + shiftChangeCashier.amount }}</span>
      </div>
      <el-form :model="shiftChangeCashier" label-width="auto">
        <el-form-item :label="$t('msg.orderManage.cashierTakingOverTheShift')">
          <el-input v-model="shiftChangeCashier.personName" disabled />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="flex j-center">
          <el-button @click="shiftChangeCashierConfirmationDialog = false">
            {{ $t('msg.common.cancel') }}
          </el-button>
          <el-button type="primary" @click="shiftChangeCashierConfirm">
            {{ $t('msg.common.confirm') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { loadStore } from '@/utils/store'
import { isShopPerm } from '@/utils/perm'
const i18n = useI18n()

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update:modelValue'])
const selectCounterDialogFlag = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  },
})

const selectCounterList = ref([])
const selectCounterId = ref(null)

const handleSelectCounterCurrentChange = (val) => {
  if (!val || val.status === 1) {
    return
  }
  selectCounterId.value = val.id
}

const getCashierCounterList = () => {
  loadStore('shopOrder/getCashierCounterList').then((data) => {
    selectCounterId.value = null
    selectCounterList.value.splice(0, selectCounterList.value.length)
    selectCounterList.value = data
    selectCounterDialogFlag.value = true
  })
}

// 小票机确认选择
const confirmSelected = () => {
  loadStore('shopOrder/startShift', selectCounterId.value).then((data) => {
    selectCounterDialogFlag.value = false
    setTimeout(() => {
      window.open('/shop/checkout')
    }, 100)
  })
}

// #region 点击强制下线
// 选择的收银台
const forcedOfflineSelectCounterId = ref(null)
// 权限不足弹框
const insufficientPermissionsDialog = ref(false)
const forcedOffline = (id) => {
  forcedOfflineSelectCounterId.value = id
  if (!isShopPerm('forcedOffline')) {
    // 没权限
    selectCounterDialogFlag.value = false
    insufficientPermissionsDialog.value = true
  } else {
    // 有权限
    loadStore('shopOrder/getShiftInfo', id).then((data) => {
      shiftChangeCashier.value = data
      selectCounterDialogFlag.value = false
      shiftChangeCashierConfirmationDialog.value = true
    })
  }
}
// #endregion

// #region 选择管理员
const administratorOptions = ref([])
const selectAdministrator = () => {
  selectAdmin.value = null
  resetAdministratorForm()
  loadStore('shopOrder/getOfflineAdminLabel').then((data) => {
    administratorOptions.value.splice(0, administratorOptions.value.length)
    administratorOptions.value = data
    insufficientPermissionsDialog.value = false
    administratorDialog.value = true
  })
}
// #endregion

// #region 管理员选择变更
const selectAdmin = ref(null)
const administratorChange = (item) => {
  selectAdmin.value = item
  administratorForm.value.id = item.value
  administratorForm.value.email = item.email
}
// #endregion

// #region 管理员验证
const administratorDialog = ref(false)
const administratorFormRef = ref(null)
const administratorForm = ref({
  id: '',
  email: '',
  password: '',
  counterId: '',
})
const administratorFormRules = ref({
  id: [
    {
      required: true,
      message: i18n.t('msg.goodsManage.cannotBeEmpty'),
      trigger: 'blur',
    },
  ],
  password: [
    {
      required: true,
      message: i18n.t('msg.goodsManage.cannotBeEmpty'),
      trigger: 'blur',
    },
  ],
})

const resetAdministratorForm = () => {
  administratorForm.value = {
    id: '',
    email: '',
    password: '',
    counterId: '',
  }
}

const adminVerification = async () => {
  await administratorFormRef.value.validate((valid, fields) => {
    if (valid) {
      const params = administratorForm.value
      params.counterId = forcedOfflineSelectCounterId.value
      loadStore('shopOrder/adminVerification', params).then((data) => {
        shiftChangeCashier.value = data
        administratorDialog.value = false
        shiftChangeCashierConfirmationDialog.value = true
      })
    }
  })
}
// #endregion

// #region 换班收银员确认
const shiftChangeCashierConfirmationDialog = ref(false)
const shiftChangeCashier = ref({
  counterName: '',
  amount: '',
  personName: '',
})

const shiftChangeCashierConfirm = () => {
  const params = {
    id: forcedOfflineSelectCounterId.value,
  }
  if (!isShopPerm('forcedOffline')) {
    params.adminId = administratorForm.value.id
    params.email = administratorForm.value.email
    params.password = administratorForm.value.password
  }
  loadStore('shopOrder/forcedOffline', params, null, null, () => {
    shiftChangeCashierConfirmationDialog.value = false
    selectAdmin.value = null
  }).then((data) => {
    setTimeout(() => {
      window.open('/shop/checkout')
    }, 100)
  })
}
// #endregion

defineExpose({
  getCashierCounterList,
})
</script>

<style lang="scss" scoped></style>
