import request from '@/utils/request'

/**
 * 登录
 * @param {*} data 请求数据
 * @returns Promise
 */
export const login = (data) => {
  return request({
    url: '/user/login',
    method: 'POST',
    data,
  })
}

/**
 * 发送邮箱验证码
 */
export const getVerifyCode = (data) => {
  return request({
    url: '/user/getVerifyCode',
    method: 'POST',
    data,
  })
}

/**
 * 获取重置密码时的验证码
 */
export const getResetPassVerifyCode = (data) => {
  return request({
    url: '/user/getResetPassVerifyCode',
    method: 'POST',
    data,
  })
}

/**
 * 重置密码
 */
export const resetPass = (data) => {
  return request({
    url: '/user/resetPass',
    method: 'POST',
    data,
  })
}

/**
 * 注册
 * @param {*} data 请求数据
 * @returns Promise
 */
export const register = (data) => {
  return request({
    url: '/user/register',
    method: 'POST',
    data,
  })
}

/**
 * 店铺认证
 * @param {*} data 请求数据
 * @returns Promise
 */
export const shopCert = (data) => {
  return request({
    url: '/user/shopCert',
    method: 'POST',
    data,
  })
}

/**
 * 获取用户信息
 * @returns 用户信息
 */
export const getUserInfo = (params) => {
  return request({
    url: '/user/profile',
    method: 'GET',
    params,
  })
}

/**
 * 获取用户信息
 * @returns 用户信息
 */
export const getServiceIndustryList = () => {
  return request({
    url: '/user/getServiceIndustryList',
    method: 'GET',
  })
}

/**
 * 增加根据手机号查询会员是否存在接口
 */
export const verifyUserExistsByPhone = (phone) => {
  return request({
    url: `/user/verifyUserExistsByPhone/${phone}`,
    method: 'GET',
  })
}
/**
 * 获取行业种类列表
 */
export const getIndustryClassificationList = (industryId) => {
  return request({
    url: `/user/getIndustryClassificationList/${industryId}`,
    method: 'GET',
  })
}

/**
 * 店铺认证V2
 * @param {*} data 请求数据
 * @returns Promise
 */
export const shopCertV2 = (data) => {
  return request({
    url: '/user/shopCertV2',
    method: 'POST',
    data,
  })
}

/**
 * 根据会员码查询会员是否存在
 */
export const verifyUserExistsByMemberCode = (code) => {
  return request({
    url: `/user/verifyUserExistsByMemberCode/${code}`,
    method: 'GET',
  })
}

/**
 * 获取用户组织权限列表
 */
export const busiPermissionList = () => {
  return request({
    url: '/user/busiPermissionList',
    method: 'GET',
  })
}

/**
 * 获取用户店铺权限列表
 */
export const shopPermissionList = () => {
  return request({
    url: '/shop/shopPermissionList',
    method: 'GET',
  })
}

/**
 * 退出登录
 */
export const logout = () => {
  return request({
    url: '/user/logout',
    method: 'GET',
  })
}

/**
 * 获取用户所在时区信息
 */
export const getUserTimeZone = () => {
  return request({
    url: '/user/getUserTimeZone',
    method: 'GET',
  })
}

/**
 * 获取店铺时区
 */
export const getShopTimeZone = () => {
  return request({
    url: '/shop/shopManage/getShopTimeZone',
    method: 'GET',
  })
}

/**
 * 获取子账号的组织信息
 */
export const getSubOrganizationInfo = () => {
  return request({
    url: '/user/sub/getSubOrganizationInfo',
    method: 'POST',
  })
}

/**
 * 获取账号信息
 */
export const getAccountInfo = () => {
  return request({
    url: '/user/getAccountInfo',
    method: 'GET',
  })
}

/**
 * 修改账号密码
 */
export const changePassword = (data) => {
  return request({
    url: '/user/changePassword',
    method: 'POST',
    data,
  })
}

/**
 * 修改会话时间
 */
export const changeSessionTime = (data) => {
  return request({
    url: '/user/changeSessionTime',
    method: 'POST',
    data,
  })
}

/**
 * 获取会话时间
 */
export const getSessionTime = () => {
  return request({
    url: '/user/getSessionTime',
    method: 'GET',
  })
}

/**
 * 监听当前登录人值班的收银台状态
 */
export const cashierStatus = () => {
  return request({
    url: '/shop/cashierStatus',
    method: 'GET',
  })
}
