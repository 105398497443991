// 检测店铺id 弹框退出登录
// import { loadStore } from '@/utils/store'
import { getItem } from '@/utils/localStorage'
import { TOKEN } from '@/constant'
import i18n from '@/i18n'
import { ElMessageBox } from 'element-plus'

class TokenTimer {
  // #timer 定时器  #token 获取的token
  #timer = null
  #token = null

  // 更新ShopId
  #handleSetToken = (tk) => {
    this.#token = tk
  }

  startMonitoring() {
    const tk = getItem(TOKEN)
    if (!tk) {
      return
    }
    if (!this.#token) {
      this.#handleSetToken(tk)
    }
    if (this.#timer) {
      return
    }
    this.#handleInterval()
  }

  #handleInterval = () => {
    this.#timer = setInterval(() => {
      // console.log('token：' + this.#token)
      if (this.#token) {
        const tk = getItem(TOKEN)
        // console.log('token new：' + tk)
        // 如果token清空了，代表有退出操作
        if (!tk) {
          this.#cleanInterval()
        } else if (tk !== this.#token) {
          this.#tokenReloadBox()
          this.#handleSetToken(tk)
        }
      }
    }, 1000)
  }

  #cleanInterval = () => {
    if (this.#timer) {
      clearInterval(this.#timer)
    }
  }

  #tokenReloadBox = () => {
    ElMessageBox.alert(
      i18n.global.t('msg.common.storeStatusHasChanged'),
      i18n.global.t('msg.common.tip'),
      {
        confirmButtonText: i18n.global.t('msg.common.confirm'),
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        callback: (action) => {
          window.location.reload()
        },
      },
    )
  }
}

export default new TokenTimer()
