import { getOnlineTicket, getOnDutyTicket } from '@/api/shop/shop'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getOnlineTicket(context) {
      return await getOnlineTicket()
    },
    async getOnDutyTicket(context) {
      return await getOnDutyTicket()
    },
  },
}
