// 检测店铺id 弹框退出登录
import { loadStoreNoLoading } from '@/utils/store'
import {
  getItem as getSessionItem,
  setItem as setSessionItem,
} from '@/utils/sessionStorage'
import { getItem as getLocalItem } from '@/utils/localStorage'
import { SHOP_CASHIER_STATUS, SHOP_ID, SHOP_CASHIER_IS_OPEN } from '@/constant'
import i18n from '@/i18n'
import { ElNotification } from 'element-plus'

class UserShopCashierStatusTimer {
  // #timer 定时器  #source 来源。1：收银台/交班记录/订单查询。2：店铺页面
  #timer = null
  #source = null

  // 更新source
  #handleSetSource = (source) => {
    this.#source = source
  }

  startMonitoring(source) {
    this.#handleSetSource(source)
    if (this.#timer) {
      return
    }
    // 重置
    setSessionItem(SHOP_CASHIER_IS_OPEN, 0)
    this.#handleInterval()
  }

  #handleInterval = () => {
    this.#timer = setInterval(() => {
      loadStoreNoLoading('user/cashierStatus').then((data) => {
        const status = this.#cashierStatus()
        if (status === 2 && data === 1) {
          this.cashierOffLineBox()
        }
        setSessionItem(
          this.#getKey(this.#source),
          getLocalItem(SHOP_ID) + '_' + data,
        )
      })
    }, 5000)
  }

  #cleanInterval = () => {
    if (this.#timer) {
      clearInterval(this.#timer)
    }
  }

  // 小票机离线弹框
  cashierOffLineBox = () => {
    // 监听弹框
    const isClose = getSessionItem(SHOP_CASHIER_IS_OPEN)
    if (!isClose || isClose === 0) {
      setSessionItem(SHOP_CASHIER_IS_OPEN, 1)
      ElNotification({
        message: i18n.global.t('msg.common.cashierStatusChange'),
        duration: 0,
        customClass: 'refund-box-ipad',
        type: 'warning',
        onClose: () => {
          setSessionItem(SHOP_CASHIER_IS_OPEN, 0)
        },
      })
    }
  }

  #getKey = (source) => {
    return SHOP_CASHIER_STATUS + source
  }

  // 校验小票机状态 0：不值班。1：离线。2：在线 3：店铺不一致。4：不存在
  #cashierStatus = () => {
    const shopStatus = this.#getSessionCashier(this.#source)
    const shopId = getLocalItem(SHOP_ID)
    if (shopStatus) {
      const str = shopStatus.split('_')
      // 店铺不一致
      if (shopId !== str[0]) {
        return 3
      }
      return Number(str[1])
    } else {
      return 4
    }
  }

  // 获取小票机的存储
  // parasm: source 来源。1：收银台/交班记录/订单查询。2：店铺页面
  #getSessionCashier = (source) => {
    return getSessionItem(this.#getKey(source))
  }

  // 校验小票机是否在线。
  // parasm: source 来源。1：收银台/交班记录/订单查询。2：店铺页面
  // return: true：在线。false：离线
  #checkCashierIfOnline = (source) => {
    const shopStatus = this.#getSessionCashier(source)
    if (shopStatus && Number(shopStatus.split('_')[1]) === 2) {
      return true
    }
    return false
  }

  // 校验小票机是否在线并且弹框
  // parasm: source 来源。1：收银台/交班记录/订单查询。2：店铺页面
  checkCashierStatusAndPopUp = (source) => {
    const flag = this.#checkCashierIfOnline(source)
    if (!flag) {
      this.cashierOffLineBox()
    }
  }
}

export default new UserShopCashierStatusTimer()
