import request from '@/utils/request'

/**
 * 获取在线的小票机列表
 */
export const getOnlineTicket = () => {
  return request({
    url: '/shop/getOnlineTicket',
    method: 'GET',
  })
}

/**
 * 获取当班收银台小票机信息
 */
export const getOnDutyTicket = () => {
  return request({
    url: '/shop/getOnDutyTicket',
    method: 'GET',
  })
}
