import {
  getUserInfo,
  login,
  register,
  shopCert,
  getVerifyCode,
  getResetPassVerifyCode,
  resetPass,
  getServiceIndustryList,
  verifyUserExistsByPhone,
  getIndustryClassificationList,
  shopCertV2,
  verifyUserExistsByMemberCode,
  busiPermissionList,
  shopPermissionList,
  logout,
  getUserTimeZone,
  getShopTimeZone,
  getSubOrganizationInfo,
  getAccountInfo,
  changePassword,
  changeSessionTime,
  getSessionTime,
  cashierStatus,
} from '@/api/user'
import { TOKEN } from '@/constant'
import router from '@/router'
import { orgIndependentRouters, orgPrivateRoutes } from '@/router/org_res_perm'
import {
  shopIndependentRouters,
  shopPrivateRoutes,
} from '@/router/shop_res_perm'
import { removeAllItem, setItem } from '@/utils/localStorage'
import { clearNotFound, genRouter, resetNotFound } from '@/utils/route'
import { sessionTimeoutBox } from '@/components/session-timeout-box/SessionTimeoutBox'

export default {
  namespaced: true,
  state: () => ({
    userInfo: {},
    permission: {},
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setOrgPerm(state, orgPerm) {
      state.permission.orgPerm = orgPerm
    },
    setShopPerm(state, shopPerm) {
      state.permission[shopPerm.shopId] = shopPerm.res
    },
    removePermission(state) {
      state.permission = {}
    },
    setOrgDefaultIndex(state, defaultIndex) {
      state.orgDefaultIndex = defaultIndex
    },
    setShopDefaultIndex(state, defaultIndex) {
      state.shopDefaultIndex = defaultIndex
    },
  },
  actions: {
    // 登录
    async login(context, userInfo) {
      return await login(userInfo)
    },
    async getVerifyCode(context, data) {
      return await getVerifyCode(data)
    },
    async getResetPassVerifyCode(context, data) {
      return await getResetPassVerifyCode(data)
    },
    async resetPass(context, data) {
      return await resetPass(data)
    },
    async register(context, registerInfo) {
      const { username, password, code, timeZone } = registerInfo
      const data = await register({
        username,
        password,
        code,
        timeZone,
      })
      this.commit('user/setToken', data.token)
    },
    // 获取用户信息
    async getUserInfo(context) {
      const res = await getUserInfo()
      this.commit('user/setUserInfo', res)
      return res
    },
    // 退出登录
    async logout(context, serverHasExited) {
      // 后台退出
      if (!serverHasExited) {
        await logout()
      }

      context.dispatch('logoutOnlyTheFrontDesk')
    },
    // 仅前台退出登录
    async logoutOnlyTheFrontDesk(context) {
      // 清空登录 Key
      this.commit('user/setToken', '')
      // 清除缓存用户信息
      this.commit('user/setUserInfo', {})
      // 情况 local storage 缓存
      removeAllItem()
      // 清空权限
      this.commit('user/removePermission')
      // 清理 404，防止登陆时直接 404
      clearNotFound(router)
    },
    // 长时间未操作退出登录
    async sessionTimeoutLogout(context) {
      await logout()
      // 清空登录 Key
      this.commit('user/setToken', '')
      // 清除缓存用户信息
      this.commit('user/setUserInfo', {})
      // 情况 local storage 缓存
      removeAllItem()
      // 清空权限
      this.commit('user/removePermission')
      // 清理 404，防止登陆时直接 404
      clearNotFound(router)
      sessionTimeoutBox.dialog()
      // window.location.href = '/login?t=1'
    },
    async shopCert(context, shopParam) {
      return await shopCert(shopParam)
    },
    async getServiceIndustryList(context) {
      return await getServiceIndustryList()
    },
    async verifyUserExistsByPhone(context, phone) {
      return await verifyUserExistsByPhone(phone)
    },
    async getIndustryClassificationList(context, industryId) {
      return await getIndustryClassificationList(industryId)
    },
    async shopCertV2(context, shopParam) {
      return await shopCertV2(shopParam)
    },
    async verifyUserExistsByMemberCode(context, code) {
      return await verifyUserExistsByMemberCode(code)
    },
    async getOrgPerm(context) {
      const res = await busiPermissionList()
      this.commit('user/setOrgPerm', res)

      const finalRouters = genRouter(res.resourcePermissions, orgPrivateRoutes)
      finalRouters.forEach((item) => {
        router.addRoute(item)
      })
      router.addRoute(...orgIndependentRouters)
      resetNotFound(router)

      this.commit('user/setOrgDefaultIndex', finalRouters[0].path)
    },
    async getShopPerm(context, shopId) {
      const res = await shopPermissionList()
      this.commit('user/setShopPerm', { shopId, res })

      // 清理之前的店铺权限路由
      router.getRoutes().forEach((item) => {
        if (item.meta && item.meta.flag === 'shop') {
          router.removeRoute(item.name)
        }
      })

      const finalRouters = genRouter(res.resourcePermissions, shopPrivateRoutes)
      finalRouters.forEach((item) => {
        router.addRoute(item)
      })

      // router.addRoute(...shopIndependentRouters)
      shopIndependentRouters.forEach((item) => {
        router.addRoute(item)
      })
      resetNotFound(router)

      this.commit('user/setShopDefaultIndex', finalRouters[0].path)
    },
    async getUserTimeZone(context) {
      const timeZone = await getUserTimeZone()
      this.commit('app/setTimeZone', timeZone)
    },
    async getShopTimeZone(context) {
      const timeZone = await getShopTimeZone()
      this.commit('app/setTimeZone', timeZone)
    },
    async getSubOrganizationInfo(context) {
      return await getSubOrganizationInfo()
    },
    async getAccountInfo(context) {
      return await getAccountInfo()
    },
    async changePassword(context, data) {
      return await changePassword(data)
    },
    async changeSessionTime(context, data) {
      return await changeSessionTime(data)
    },
    async getSessionTime(context) {
      return await getSessionTime()
    },
    async cashierStatus(context) {
      return await cashierStatus()
    },
  },
}
