import request from '@/utils/request'

/**
 * 获取收银台label
 */
export const getCashierCounterLabel = () => {
  return request({
    url: '/shop/shiftRecord/getCashierCounterLabel',
    method: 'GET',
  })
}

/**
 * 获取收银员信息label
 */
export const getCashierInfoLabel = () => {
  return request({
    url: '/shop/shiftRecord/getCashierInfoLabel',
    method: 'GET',
  })
}

/**
 * 获取交班记录列表
 */
export const getShiftRecordList = (params) => {
  return request({
    url: '/shop/shiftRecord/getShiftRecordList',
    method: 'GET',
    params,
  })
}

/**
 * 获取交班记录详情
 */
export const getShiftRecordDetails = (id) => {
  return request({
    url: `/shop/shiftRecord/getShiftRecordDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 收款总览
 */
export const getCollectionOverview = (id) => {
  return request({
    url: `/shop/shiftRecord/getCollectionOverview/${id}`,
    method: 'GET',
  })
}
