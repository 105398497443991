<template>
  <div
    class="app-wrapper"
    :class="$store.getters.sidebarOpened ? 'openSidebar' : 'hideSidebar'"
  >
    <!-- 左侧 Menu -->
    <Sidebar
      id="guide-sidebar"
      class="sidebar-container"
      :style="{ backgroundColor: $store.getters.cssVar.menuBg }"
    ></Sidebar>
    <div class="main-container">
      <div class="fixed-header">
        <!-- 顶部 Navbar -->
        <Navbar />
      </div>
      <!-- 内容区 -->
      <AppMain></AppMain>
    </div>

    <BusinessOperatorFillsInDialog v-if="businessOperatorFillsInDialogFlag" />
  </div>
</template>

<script setup>
import { loadStore } from '@/utils/store'
import AppMain from './components/AppMain.vue'
import Navbar from './components/Navbar.vue'
import Sidebar from './components/sidebar/Sidebar.vue'
import BusinessOperatorFillsInDialog from '../global-dialog/BusinessOperatorFillsInDialog.vue'
import tokenTimer from '@/utils/tokenTimer.js'
import { ref } from 'vue'

// #region 经营人信息
const businessOperatorFillsInDialogFlag = ref(false)
// 查看 获取用户是否填写过经营人信息
loadStore('orgUser/checkWriteOwn').then((data) => {
  if (data === 0) {
    // 没有填写
    businessOperatorFillsInDialogFlag.value = true
  }
})
// #endregion

// #region 触发token变化定时器
tokenTimer.startMonitoring()
// #endregion
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.module.scss';

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width #{$sidebarDuration};
}

.hideSidebar .fixed-header {
  width: calc(100% - #{$hideSideBarWidth});
}
</style>
