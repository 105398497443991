import {
  getCashierCounterLabel,
  getCashierInfoLabel,
  getShiftRecordList,
  getShiftRecordDetails,
  getCollectionOverview,
} from '@/api/shop/dataHandoverRecord'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getCashierCounterLabel(context) {
      return await getCashierCounterLabel()
    },
    async getCashierInfoLabel(context) {
      return await getCashierInfoLabel()
    },
    async getShiftRecordList(context, params) {
      return await getShiftRecordList(params)
    },
    async getShiftRecordDetails(context, id) {
      return await getShiftRecordDetails(id)
    },
    async getCollectionOverview(context, id) {
      return await getCollectionOverview(id)
    },
  },
}
